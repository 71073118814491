<template>
  <div class="my-footer">
      <div class="my-footer-container">
          <b-row>
              <b-col>
                  <div class="d-flex align-items-center justify-content-center">
                      <div style="display:inline-grid" @click="$router.push('/')">
                          <img src="/images/design/logo.svg" style="display:block;margin-left:auto;margin-right:auto;width:25px;padding-top:7px;padding-bottom:3px" alt="">
                          <span style="font-size:14px"> {{ $t('homepage') }} </span>
                      </div>
                  </div>
              </b-col>
              <!-- <b-col>
                  <div class="d-flex align-items-center justify-content-center">
                      <div style="display:inline-grid" @click="$store.state.isOpenSearch = true">
                          <img src="/images/design/searchtop.svg" style="display:block;margin-left:auto;margin-right:auto;width:30px;padding-top:3px" alt="">
                          <span style="font-size:14px">Найти школу</span>
                      </div>
                  </div>
              </b-col> -->
              <b-col>
                  <div class="d-flex align-items-center justify-content-center">
                      <div style="display:inline-grid" @click="$store.state.isOpenSidebar = true">
                          <img src="/images/design/account.svg" style="display:block;margin-left:auto;margin-right:auto;width:30px;padding-top:3px" alt="">
                          <span style="font-size:14px"> {{ $t('profile') }} </span>
                      </div>
                  </div>
              </b-col>
          </b-row>
      </div>
  </div>
</template>

<script>
import { BRow,BCol } from 'bootstrap-vue'
export default {
    components : { BRow,BCol }
}
</script>

<style lang="scss" scope>
@import '../assets/styles/variables.scss';
    .my-footer{
        width: 100%;
        height:  $navbar-height;
        background-color: rgba(255, 255, 255, .65);  
        backdrop-filter: blur(15px);
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 3;
        display: none;
    }
</style>